<!-- 教师排名 -->
<template>
  <div>
    <shell title="年级教师榜">
      <div class="zwtzgg-css" v-if="dataArr.length == 0">
        暂无信息
      </div>
      <div v-else id="TeacherRanking" class="teacherRankingCss"></div>
    </shell>
  </div>
</template>

<script>
import * as echarts from "echarts";
import moment from "moment";
import { EvaluatorScoreRankingReport } from "@/api/index.js";
let teacherRankingChart = null;
import shell from "./shell.vue";
export default {
  components: {
    shell,
  },
  data() {
    return {
      dataArr: [],
      timer: null,
    };
  },
  created() {
    this.getEvaluatorScoreRankingReport3();
    this.timer = setInterval(() => {
      this.getEvaluatorScoreRankingReport3();
    }, 1000 * 60 * 60);
  },
  beforeDestroy() {
    if (this.timer) {
      clearInterval(this.timer);
    }
  },
  methods: {
    // 教师
    getEvaluatorScoreRankingReport3() {
      let gradeNum = localStorage.getItem("gradeNum");
      let ya = moment().format("YYYY");
      let mm = Number(moment().format("MM"));
      if (mm < 9) {
        ya -= 1;
      }
      EvaluatorScoreRankingReport({
        top: 10,
        Grade: Number(ya) - Number(gradeNum) + 1,
        Evaltype: 3,
      }).then((res) => {
        this.dataArr = res.data;
        if(this.dataArr.length > 0){
          this.TeacherRanking();
        }
      });
    },
    // 根据不同的屏幕宽度换算字体大小
    transformFontSize(fontsize) {
      // 获取屏幕宽度
      const width = window.screen.width;
      const ratio = width / 1920;
      // 取下整
      return parseInt(fontsize * ratio);
    },
    async TeacherRanking() {
      let yAxis = [];
      let dataAs = [];
      this.dataArr.map((val) => {
        yAxis.push(val.userName);
        dataAs.push(val.allCount);
      });
      if (teacherRankingChart) {
        teacherRankingChart.dispose();
      }

      teacherRankingChart = null;
      this.$forceUpdate();
      await new Promise((res) => setTimeout(res, 50));
      teacherRankingChart = echarts.init(document.getElementById("TeacherRanking"));
      // 绘制图表
      teacherRankingChart.setOption({
        tooltip: { show: false },
        grid: { left: this.transformFontSize(15), top: 0, bottom: 5, right: this.transformFontSize(60), containLabel: true },
        xAxis: {
          type: "value",
          boundaryGap: false,
          axisLine: { show: false },
          axisTick: { show: false },
          axisLabel: { show: false },
          splitLine: { show: false },
          max: Math.max(...dataAs), // 使用数组最大值，否则显示会有问题
        },
        yAxis: {
          type: "category",
          data: yAxis,
          axisLine: { show: false },
          axisTick: { show: false },
          axisLabel: { fontSize: this.transformFontSize(16), color: "#333", margin: 10, padding: 0 },
          inverse: true,
        },
        series: [
          {
            name: "数量",
            type: "bar",
            itemStyle: {
              borderRadius: 100,
              color: function(params) {
                // 定义一个颜色集合
                let colorList = ["#52A8FF", "#00B389", "#FFA940", "#FF5A57", "#29EFC4", "#F8AEA4", "#FFC53D", "#009982", "#C099FC", "#F5855F"];
                return colorList[params.dataIndex];
              },
            },
            barMaxWidth: 16,
            label: { show: true, position: "insideRight", fontSize: this.transformFontSize(15), offset: [this.transformFontSize(50), 2], color: "#555" },
            data: dataAs,
          },
        ],
      });
    },
  },
};
</script>

<style scoped>
.teacherRankingCss {
  width: 380px;
  height: 354px;
}
.zwtzgg-css {
  word-break: break-all;
  word-wrap: break-word;
  font-family: "crjk";
  color: #7457f8;
  font-size: 25px;
  font-weight: 600;
  text-align: center;
  width: 380px;
  height: 354px;
  padding-top: 200px;
  box-sizing: border-box;
}
</style>
