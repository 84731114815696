<!-- 德智体美劳 -->
<template>
  <div>
    <shell title="德智体美劳近期评价数据">
      <div id="Intellectual" class="intellectualCss"></div>
    </shell>
  </div>
</template>

<script>
import * as echarts from "echarts";
import shell from "./shell.vue";
import { GradeReportGrowthTrends } from "@/api/index.js";
let intellectualChart = null;
let setIn = null;
export default {
  components: {
    shell,
  },
  data() {
    return {
      listArr:{},
      timer:null
    };
  },
  created(){
    this.getGradeReportGrowthTrends();
    this.timer = setInterval(() => {
      this.getGradeReportGrowthTrends();
    }, 1000 * 60 * 60);
  },
  beforeDestroy() {
    if (this.timer) {
      clearInterval(this.timer);
    }
  },
  methods: {
    // 获取全校德智体美劳
    getGradeReportGrowthTrends() {
      GradeReportGrowthTrends({
        iType: 1
      }).then((res) => {
        let gradeNum = localStorage.getItem("gradeNum");
        res.data.map(val =>{
          if(val.entranceYear == gradeNum){
            this.listArr = val.Charts;
          }
        })
        this.Intellectual();
      });
    },
    // 根据不同的屏幕宽度换算字体大小
    transformFontSize(fontsize) {
        // 获取屏幕宽度
      const width = window.screen.width
      const ratio = width / 1920
      // 取下整
      return parseInt(fontsize * ratio)
    },
    async Intellectual() {
      if (intellectualChart) {
        intellectualChart.dispose();
      }
      if(setIn){
        clearInterval(setIn)
      }

      intellectualChart = null;
      this.$forceUpdate();
      await new Promise((res) => setTimeout(res, 50));
      intellectualChart = echarts.init(document.getElementById("Intellectual"));
      let seriesArr = [];
      let colorArr = ["0, 62, 246", "0, 193, 142", "253, 148, 67","231, 18, 188", "231, 73, 18"];

      let abArr = [];
      this.listArr.Series.forEach((val, index) => {
        abArr = [...abArr, ...val.seriesData];
        seriesArr.push({
          name: val.seriesName,
          type: "line",
          symbolSize: this.transformFontSize(6),
          data: val.seriesData,
          areaStyle: {
            normal: {
              color: new echarts.graphic.LinearGradient(
                0,
                0,
                0,
                1,
                [
                  {
                    offset: 0,
                    color: `rgba(${colorArr[index]},.2)`,
                  },
                  {
                    offset: 1,
                    color: "rgba(255, 255, 255,0)",
                  },
                ],
                false
              ),
            },
          },
          itemStyle: {
            normal: {
              color: `rgb(${colorArr[index]})`,
            },
          },
          lineStyle: {
            normal: {
              width: this.transformFontSize(2),
              shadowColor: `rgba(${colorArr[index]}, .2)`,
              shadowBlur: this.transformFontSize(4),
              shadowOffsetY: this.transformFontSize(25),
            },
          },
        });
      });
      
      const min = Math.min(...abArr);
      // 绘制图表
      intellectualChart.setOption({
        tooltip: {
          trigger: "axis",
          axisPointer: {
            lineStyle: {
              color: "#ddd",
            },
          },
          backgroundColor: "rgba(255,255,255,1)",
        },
        legend: {
          right: this.transformFontSize(10),
          top: 0,
          textStyle: {
            color: "#000",
            fontSize: this.transformFontSize(14),
            fontWeight: 600,
          },
          data: this.listArr.Series.map((val) => {
            return val.seriesName;
          }),
        },
        grid: {
          left: this.transformFontSize(10),
          right: this.transformFontSize(20),
          bottom: this.transformFontSize(5),
          top:this.transformFontSize(40),
          containLabel: true,
        },
        xAxis: {
          type: "category",
          data: this.listArr.xAxis,
          boundaryGap: false,
          splitLine: {
            show: true,
            interval: "auto",
            lineStyle: {
              type: "dashed",
              color: ["#ccc"],
            },
          },
          axisTick: {
            show: false,
          },
          axisLine: {
            lineStyle: {
              color: "#ccc",
            },
          },
          axisLabel: {
            textStyle: {
              fontSize: this.transformFontSize(12),
              color: "#666",
            },
          },
        },
        yAxis: [
          {
            type: "value",
            splitLine: {
              show: true,
              lineStyle: {
                type: "dashed",
                color: ["#ccc"],
              },
            },
            axisTick: {
              show: false,
            },
            axisLine: {
              show: true,
              lineStyle: {
                fontSize: this.transformFontSize(12),
                color: "#ccc",
              },
            },
            axisLabel: {
              textStyle: {
                fontSize: this.transformFontSize(12),
                color: "#666",
              },
            },
            min: min - (min % 100), // 取最小数，往下取100的整数。例如 1059，取1000
          },
        ],
        series: seriesArr,
      });
      var currentIndex = -1
      setIn = setInterval(() => {
        var dataLen = this.listArr.xAxis.length
        // 取消之前高亮的图形
        intellectualChart.dispatchAction({
          type: 'downplay',
          seriesIndex: 0,
          dataIndex: currentIndex
        })
        currentIndex = (currentIndex + 1) % dataLen
        // 高亮当前图形
        intellectualChart.dispatchAction({
          type: 'highlight',
          seriesIndex: 0,
          dataIndex: currentIndex
        })
        // 显示 tooltip
        intellectualChart.dispatchAction({
          type: 'showTip',
          seriesIndex: 0,
          dataIndex: currentIndex
        })
      }, 3000)
    },
  },
};
</script>

<style scoped>
.intellectualCss {
  width: 660px;
  height: 390px;
  margin-top: -36px;
}
</style>
