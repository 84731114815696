<template>
  <div class="notification_box_css">
    <div class="zwtzgg-css" v-if="listArr.length == 0">
      暂无通知公告
    </div>
    <el-carousel v-else height="24.6rem" ref="carousel" :autoplay="false" arrow="never" trigger="click">
      <el-carousel-item v-for="(item, index) in listArr" :key="index">
        <div class="title-box-css">{{ item.hwTitle }}</div>
        <div class="time-box-css">{{ item.sendTime }}</div>
        <div class="content-box-css">
          <div :id="'carousel' + index">
            {{ item.hwContent }}
          </div>
        </div>
      </el-carousel-item>
    </el-carousel>
  </div>
</template>

<script>
import { getClassTask } from "@/api/index.js";
import moment from "moment";
export default {
  data() {
    return {
      carouselNum: 0,
      timer: null, // 定时器名称
      listArr: [],
    };
  },
  created() {
    this.getStudentScoreRankingReport();
    this.timer = setInterval(() => {
      this.getStudentScoreRankingReport();
    }, 1000 * 60 * 60);
  },
  beforeDestroy() {
    if (this.timer) {
      clearInterval(this.timer);
    }
  },
  methods: {
    getStudentScoreRankingReport() {
      let _this = this;
      getClassTask({
        ClassID: localStorage.getItem("gradeNum"),
        startindex: 1,
        endindex: 200,
        startDate: moment().format("YYYY-MM-DD"),
        endDate: moment()
          .add(1, "days")
          .format("YYYY-MM-DD"),
        hwType: 5,
      }).then((res) => {
        this.listArr = res.data;
        this.$nextTick(() => {
          if(_this.listArr.length > 0){
            _this.getHeight(0);
          }
        });
      });
    },
    // 获取内容高度，处理动画滚动高度及滚动时间，使每个滚动速度保持一致
    getHeight(num) {
      this.$nextTick(() => {
        this.$refs.carousel.setActiveItem(num);
        let hei = document.getElementById("carousel" + num).offsetHeight;
        if (hei < 445) {
          setTimeout(() => {
            this.getHeight((num + 1) % this.listArr.length);
          }, 5000);
        } else {
          let heiRem = (hei - 300) / 20;
          const notificationRowup = `@keyframes notificationRowup {
            0% {
                -webkit-transform: translate3d(0, 0, 0);
                transform: translate3d(0, 0, 0);
            }
            100% {
                -webkit-transform: translate3d(0, -${heiRem}rem, 0);
                transform: translate3d(0, -${heiRem}rem, 0);
            }
            }`;
          const style = document.createElement("style");
          style.setAttribute("type", "text/css");
          document.head.appendChild(style);
          style.sheet.insertRule(notificationRowup, 0);
          setTimeout(() => {
            document.getElementById("carousel" + num).style.animation = `${(heiRem / 3).toFixed(1)}s notificationRowup linear`;
          }, 3000);
          setTimeout(() => {
            document.getElementById("carousel" + num).removeAttribute("style");
            this.getHeight((num + 1) % this.listArr.length);
          }, (Number((heiRem / 3).toFixed(1)) + 2.9) * 1000);
        }
      });
    },
  },
};
</script>

<style scoped>
/deep/ .el-carousel__button {
  width: 10px;
}
/deep/ .el-carousel__indicators--horizontal {
  bottom: 0;
  left: 0 !important;
  right: 0 !important;
  transform: none;
  text-align: center;
}
.notification_box_css {
  width: 430px;
  height: 490px;
  background-color: #ffffff52;
  border-radius: 10px;
  overflow: hidden;
  border: #fff 1px solid;
  box-sizing: border-box;
}
.title-box-css {
  text-align: center;
  padding: 8px 10px 0;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
  overflow: hidden;
  word-break: break-all;
  word-wrap: break-word;
  font-family: "crjk";
  color: #7457f8;
  font-size: 25px;
  font-weight: 600;
}
.time-box-css {
  text-align: center;
  font-size: 20px;
  color: #33333371;
  margin-top: -5px;
}
.content-box-css {
  text-indent: 34px;
  font-size: 20px;
  color: #333333c5;
  padding: 0 15px;
  height: 445px;
  overflow: hidden;
  line-height: 28px;
}
.content-box-css > div {
  position: relative;
}
.zwtzgg-css{
  word-break: break-all;
  word-wrap: break-word;
  font-family: "crjk";
  color: #7457f8;
  font-size: 25px;
  font-weight: 600;
  text-align: center;
  padding-top: 200px;
}
</style>
