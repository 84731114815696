<template>
  <div>
    <shell :title="allData[showNum].lessonName + ' 出勤情况'">
      <div class="box-flex-css">
        <div class="dailyAttendance_box_css">
          <div class="flex_css fixed_Css">
            <div>班级</div>
            <div>应到</div>
            <div>实到</div>
            <div>请假</div>
          </div>
          <div class="flex_css" v-for="(item, index) in allData[showNum].allClass.slice(0,8)" :key="index">
            <div>{{ item.className }}</div>
            <div>{{ item.YD }}</div>
            <div>{{ item.SD }}</div>
            <div>{{ item.QJ }}</div>
          </div>
          <div class="flex_css fixed_Css" v-if="allData[showNum].allClass.length < 8">
            <div>合计</div>
            <div>{{getNumber('YD')}}</div>
            <div>{{getNumber('SD')}}</div>
            <div>{{getNumber('QJ')}}</div>
          </div>
        </div>
        <div v-if="allData[showNum].allClass.length > 7" class="dailyAttendance_box_css" style="border-left:1px solid #e4e4fa;">
          <div class="flex_css fixed_Css">
            <div>班级</div>
            <div>应到</div>
            <div>实到</div>
            <div>请假</div>
          </div>
          <div class="flex_css" v-for="(item, index) in allData[showNum].allClass.slice(8,allData[showNum].allClass.length)" :key="index">
            <div>{{ item.className }}</div>
            <div>{{ item.YD }}</div>
            <div>{{ item.SD }}</div>
            <div>{{ item.QJ }}</div>
          </div>
          <div class="flex_css fixed_Css">
            <div>合计</div>
            <div>{{getNumber('YD')}}</div>
            <div>{{getNumber('SD')}}</div>
            <div>{{getNumber('QJ')}}</div>
          </div>
        </div>
      </div>
    </shell>
  </div>
</template>

<script>
import shell from "./shell.vue";
import { getGradeAttendanceReportV4 } from "@/api/index.js";
import moment from "moment";
let refreshTime = null; // 刷新接口
let getTime1m = null; // 1分钟更新状态
let getTime2m = null; // 2分钟切换课次
export default {
  components: {
    shell,
  },
  data() {
    return {
      // 必须默认有第一个，否则html部分抛错
      allData:[
        {
          lessonName:'第一节课',
          allClass:[]
        }
      ],
      currentNum: 0, // 第几节课
      showNum: 0, // 显示哪一节课
    };
  },
  created(){
    getGradeAttendanceReportV4({
      entranceYear:localStorage.getItem("gradeNum"),
      StartTime:moment().format("YYYY-MM-DD")
    }).then(res =>{
      this.allData = res.data;
      // 一分钟更新一次状态
      getTime1m = setInterval(() =>{
        this.getTime();
      },1000*60)
      this.showNum = 0;
      getTime2m = setInterval(() =>{
        if(this.showNum < this.currentNum){
          this.showNum += 1
        } else {
          this.showNum = 0
        }
        this.$forceUpdate()
      },1000*60*2)
    })
  },
  beforeDestroy() {
    if (getTime1m) {
      clearInterval(getTime1m);
    }
    if (getTime2m) {
      clearInterval(getTime2m);
    }
    if (refreshTime) {
      clearInterval(refreshTime);
    }
  },
  methods:{
    getTime(){
      let allArr = []
      console.log(this.allData,'this.allData')
      this.allData.map(val =>{
        allArr.push({
          lessonName: val.lessonName,
          startTime: val.startTime,
          endTime:  val.endTime
        })
      })
      let currentTime = moment().format("HH:mm:ss"); // 当前时间
      let YMD = moment().format("YYYY-MM-DD"); // 当天几号

      let numBoolV = true;
      // 判断当前是哪一节课
      for(let i=0;i<allArr.length;i++){
        let startTime = YMD + ' ' + allArr[i].startTime; // 上课时间
        let endTime = YMD + ' ' + allArr[i].endTime; // 下课时间
        let abTime = moment().isBetween(startTime, endTime, 'time');
        if(abTime){
          this.currentNum = i;
          numBoolV = false;
          break
        }
      }
      if(numBoolV){
        // 如果不在上课，取上一节课的考勤
        if(currentTime > allArr[allArr.length-1].endTime){
            this.currentNum = allArr.length-1;
        }else {
          for(let i=0;i<allArr.length;i++){
            if(currentTime < allArr[i].startTime){
              this.currentNum = i - 1;
              break
            }
          }
        }
      }

      if(currentTime < allArr[0].startTime || currentTime > allArr[allArr.length-1].endTime){
        // 如果当时间 小于第一节课 或者 大于最后一节课 不刷新
        // 将刷新接口计时器关闭
        if (refreshTime) {
          clearInterval(refreshTime);
        }
        return
      } else{
        let numBool = true;
        for(let i=0;i<allArr.length;i++){
          let startTime = YMD + ' ' + allArr[i].startTime; // 上课时间
          let endTime = YMD + ' ' + allArr[i].endTime; // 下课时间
          let before10 = moment(startTime).subtract(10, 'm').format('YYYY-MM-DD HH:mm:ss'); // 课前十分钟
          let after10 = moment(endTime).add(10, 'm').format('YYYY-MM-DD HH:mm:ss'); // 课后十分钟
          let abTimeA = moment().isBetween(before10, startTime, 'time');
          let abTimeB = moment().isBetween(endTime, after10, 'time');
          if(abTimeA || abTimeB){
            // 上课开始前10分钟和课后10分钟  1分钟刷新一次
            // 由于该方法一分钟更新一次，这里则直接刷新，并将刷新接口计时器关闭
            if (refreshTime) {
              clearInterval(refreshTime);
            }
            this.getData();
            numBool = false;
            return
          }
        }
        // 其他时间10分钟刷新一次
        if(numBool){
          // 没有计时器则加入并立刻进行一次刷新
          if (!refreshTime) {
            let _this = this
            this.getData()
            refreshTime = setInterval(()=>{
              _this.getData()
            },1000*60*10)
          }
        }
      }
    },
    getData(){
      getGradeAttendanceReportV4({
        entranceYear:localStorage.getItem("gradeNum"),
        StartTime:moment().format("YYYY-MM-DD")
      }).then(res =>{
        this.allData = res.data;
      })
    },
    // 求和
    getNumber(value){
      return this.allData[this.showNum].allClass.reduce((p,e)=>p+e[value],0)
    }
  }
};
</script>

<style scoped>
.tableACss {
  width: 810px;
  height: 364px;
}
.box-flex-css{
    margin-top:-10px;border-radius:10px;overflow: hidden;
    display: flex;
    width: 810px;
}
.dailyAttendance_box_css {
  flex: 1;
  height: 364px;
  overflow: auto;
}
.flex_css {
  display: flex;
  align-items: center;
  font-size: 16px;
  text-align: center;
  color: #4b4a4a;
  padding: 9.5px 0;
}
.fixed_Css {
  background-color: #f0f0ff !important;
  font-weight: bold;
  color: #5a5757;
}
.flex_css:nth-child(2n) {
  background-color: #f9f7ff88;
}
.flex_css > div {
  flex: 1;
  flex-shrink: 0;
  padding: 0 2px;
}
.flex_css > div:nth-child(1) {
  width: 110px !important;
}
.flex_css > div:nth-child(2) {
  width: 50px !important;
}
</style>
