<!-- 班级学生排名 -->
<template>
  <div class="classStudent_box_css">
    <div class="className-box-css">{{ gradeName }}</div>
    <div class="zwtzgg-css" v-if="listArr.length == 0">
      暂无得分总榜
    </div>
    <div style="overflow: hidden;" v-else>
      <div class="studentList-box-css animation-css">
        <div
          class="studentList-item-css"
          v-for="(item, index) in listArr"
          :key="index"
        >
          <div class="ranking_index_css">
            <img
              v-if="item.ranking == 1"
              src="@/assets/largeScreen/huangguandiyiming.png"
            />
            <img
              v-else-if="item.ranking == 2"
              src="@/assets/largeScreen/huangguandierming.png"
            />
            <img
              v-else-if="item.ranking == 3"
              src="@/assets/largeScreen/huangguandisanming.png"
            />
            <div v-else>{{ item.ranking }}</div>
          </div>
          <img
            class="ranking_image_css"
            :src="item.photo"
            @error="imgError(index)"
          />
          <div class="width_css">
            <div class="ranking_userName_css">{{ item.userName }}</div>
          </div>
          <div class="ranking_zanCount_css">{{ getUnit(item.Score) }}</div>
        </div>
      </div>
    </div>
    <!-- <div v-else class="seamlessScroll-box">
      <vue-seamless-scroll :data="listArr" :class-option="optionSingleHeightTime">
        <div class="studentList-box-css">
          <div class="studentList-item-css" v-for="(item, index) in listArr" :key="index">
            <div class="ranking_index_css">
              <img v-show="item.ranking == 1" src="@/assets/largeScreen/huangguandiyiming.png" />
              <img v-show="item.ranking == 2" src="@/assets/largeScreen/huangguandierming.png" />
              <img v-show="item.ranking == 3" src="@/assets/largeScreen/huangguandisanming.png" />
              <div v-show="item.ranking > 3">{{ item.ranking }}</div>
            </div>
            <img class="ranking_image_css" :src="item.photo"/>
            <div class="width_css">
              <div class="ranking_userName_css">{{ item.userName }}</div>
            </div>
            <div class="ranking_zanCount_css">{{ getUnit(item.Score) }}</div>
          </div>
        </div>
      </vue-seamless-scroll>
    </div> -->
  </div>
</template>

<script>
import getAva from '@/utils/photoList'
// 处理无头像显示
import { fillPhoto } from '@/utils/photoList'
import moment from 'moment'
import vueSeamlessScroll from 'vue-seamless-scroll'
import { StudentScoreRankingReport } from '@/api/index.js'
export default {
  components: { vueSeamlessScroll },
  data() {
    return {
      gradeName: '得分总榜',
      showScroll: false,
      listArr: [],
      timer: null,
    }
  },
  computed: {
    optionSingleHeightTime() {
      return {
        step: 0.6, // 数值越大速度滚动越快
        limitMoveNum: 2, // 开始无缝滚动的数据量 this.dataList.length
        hoverStop: true, // 是否开启鼠标悬停stop
        direction: 1, // 0向下 1向上 2向左 3向右
        openWatch: true, // 开启数据实时监控刷新dom
        singleHeight: 0, // 单步运动停止的高度(默认值0是无缝不停止的滚动) direction => 0/1
        singleWidth: 0, // 单步运动停止的宽度(默认值0是无缝不停止的滚动) direction => 2/3
        waitTime: 1000, // 单步运动停止的时间(默认值1000ms)
        switchDelay: 10,
      }
    },
  },
  created() {
    // this.getList()
    this.getStudentScoreRankingReport()
    this.timer = setInterval(() => {
      this.getStudentScoreRankingReport()
    }, 1000 * 60 * 60)
  },
  beforeDestroy() {
    if (this.timer) {
      clearInterval(this.timer)
    }
  },
  methods: {
    getStudentScoreRankingReport() {
      let gradeNum = localStorage.getItem('gradeNum')
      let ya = moment().format('YYYY')
      let mm = Number(moment().format('MM'))
      if (mm < 9) {
        ya -= 1
      }
      let Grade = Number(ya) - Number(gradeNum) + 1
      let titleVal = ''
      if (Grade == 1) {
        titleVal = '一'
      } else if (Grade == 2) {
        titleVal = '二'
      } else if (Grade == 3) {
        titleVal = '三'
      } else if (Grade == 4) {
        titleVal = '四'
      } else if (Grade == 5) {
        titleVal = '五'
      } else if (Grade == 6) {
        titleVal = '六'
      }
      this.gradeName = titleVal + '年级得分总榜'
      StudentScoreRankingReport({
        top: 50,
        Grade: Grade,
      }).then((res) => {
        this.listArr = fillPhoto(res.data)
        if (res.data.length > 0) {
          this.getList()
        }
      })
    },
    async getList() {
      let _this = this
      // 由于图片比较慢，所以先循环加入排名
      for (let i = 0; i < this.listArr.length; i++) {
        this.listArr[i].ranking = i + 1
      }
      for (let i = 0; i < this.listArr.length; i++) {
        try {
          const img = await this.loadImage(i)
        } catch (err) {}
      }
      if (this.listArr.length > 20) {
        setTimeout(() => {
          this.showScroll = true
        }, 1000)
      } else {
        this.showScroll = false
      }
    },
    loadImage(i) {
      return new Promise((resolve, reject) => {
        const img = new Image()
        img.src = this.listArr[i].photo
        img.onload = () => {
          resolve(true)
        }
        img.onerror = () => {
          this.listArr[i].photo = getAva()
          reject(false)
        }
      })
    },
    getUnit(e) {
      let size = Number(e)
      if (size < 10000) {
        return size
      } else if (size < 10000 * 1000) {
        return (size / 10000).toFixed(2) + '万'
      } else {
        return (size / (10000 * 1000)).toFixed(2) + '千万'
      }
    },
    imgError(idx) {
      this.listArr[idx].photo = getAva()
    },
  },
}
</script>
<style scoped>
.classStudent_box_css {
  width: 430px;
  height: 490px;
  background-color: #ffffff52;
  border-radius: 10px;
  overflow: hidden;
  border: #fff 1px solid;
  box-sizing: border-box;
  padding: 8px 8px 0;
}
.className-box-css {
  text-align: center;
  margin-bottom: 3px;
  font-family: 'crjk';
  color: #8a70ff;
  font-size: 25px;
  font-weight: 600;
}
.animation-css {
  animation: 20s rankingByClassRowup linear infinite;
}
@keyframes rankingByClassRowup {
  0% {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
  100% {
    -webkit-transform: translate3d(0, -1240px, 0);
    transform: translate3d(0, -1240px, 0);
  }
}
.studentList-box-css {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  padding-top: 350px;
}
.studentList-item-css {
  display: flex;
  align-items: center;
  margin-bottom: 8px;
  width: 48.3%;
  flex-shrink: 0;
}
.ranking_image_css {
  width: 35px;
  height: 35px;
  border-radius: 1000px;
  flex-shrink: 0;
  margin-right: 6px;
  object-fit: cover;
}
.ranking_index_css {
  flex-shrink: 0;
  width: 30px;
  height: 30px;
  margin-right: 6px;
  text-align: center;
  line-height: 30px;
  font-size: 18px;
  color: #a0a0a0a6;
  font-weight: bold;
}
.ranking_index_css > img {
  width: 30px;
  height: 30px;
}
.ranking_userName_css {
  font-size: 16px;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
  overflow: hidden;
  word-break: break-all;
  word-wrap: break-word;
}
.ranking_zanCount_css {
  font-size: 16px;
  flex-shrink: 0;
  margin-left: 10px;
}
.width_css {
  flex-grow: 1;
}
.seamlessScroll-box {
  height: 450px;
  overflow: hidden;
}
.zwtzgg-css {
  word-break: break-all;
  word-wrap: break-word;
  font-family: 'crjk';
  color: #7457f8;
  font-size: 25px;
  font-weight: 600;
  text-align: center;
  padding-top: 200px;
}
</style>
